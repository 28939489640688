import React, { useContext, useState } from 'react'
import './NavLinks.css'
import './Dropdown.css'
import { NavLink, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../shared/context/auth-context'
//import { useAuth } from '../../shared/hooks/auth-hook'

const NavLinks = (props) => {
	const auth = useContext(AuthContext)
	const [loggedOut, setLoggedOut] = useState(false)
	//const { logout } = useAuth()
	let navigate = useNavigate()
	//
	//
	//
	//
	//

	const logoutStuff = () => {
		console.log('inside logoutStuff')
		setLoggedOut(true)
	}

	if (loggedOut) {
		console.log('user is logged out')
		navigate('/admin/login')
		auth.logout()
	}
	//
	//
	//
	//
	//
	//
	//
	//
	//
	//
	//

	return (
		<ul className='nav-links'>
			{auth.isLoggedIn && (
				<li>
					<NavLink to='/admin/team/new' exact>
						NEW TEAM
					</NavLink>
				</li>
			)}
			{/* <h2>|</h2> */}
			{auth.isLoggedIn && (
				<li>
					<NavLink to='/admin/team/current' exact>
						CURRENT TEAM
					</NavLink>
				</li>
			)}
			{/* <h2>|</h2> */}
			{auth.isLoggedIn && (
				<li>
					<NavLink to='/admin/teams/archive' exact>
						ARCHIVED TEAMS
					</NavLink>
				</li>
			)}
			{/* <h2>|</h2> */}
			{/* for adding a new player(s) to the SYSTEM */}
			{auth.isLoggedIn && (
				<li>
					<NavLink to='/admin/newPlayer' exact>
						ADD PLAYERS
					</NavLink>
				</li>
			)}
			{/* <h2>|</h2> */}
			{auth.isLoggedIn && (
				<li>
					<NavLink to='/admin/getAllPlayers' exact>
						ALL PLAYERS
					</NavLink>
				</li>
			)}
			{/* <h2>|</h2> */}
			{auth.isLoggedIn && (
				<li>
					<NavLink to='/games/new' exact>
						ADD GAMES
					</NavLink>
				</li>
			)}
			{/* <h2>|</h2> */}
			{auth.isLoggedIn && (
				<li>
					<NavLink to='/admin/events/new' exact>
						ADD EVENT
					</NavLink>
				</li>
			)}
			{/* <h2>|</h2> */}
			{auth.isLoggedIn && (
				<li>
					<NavLink to='/admin/registration' exact>
						REGISTRATION
					</NavLink>
				</li>
			)}
			{auth.isLoggedIn && (
				<li>
					<NavLink to='/admin/masterSchedule' exact>
						SCHEDULE
					</NavLink>
				</li>
			)}
			{auth.isLoggedIn && (
				<li>
					<NavLink to='/admin/venues' exact>
						VENUES
					</NavLink>
				</li>
			)}
			{auth.isLoggedIn && (
				<li>
					<NavLink to='/admin/videos' exact>
						VIDEOS
					</NavLink>
				</li>
			)}
			{auth.isLoggedIn && (
				<li>
					<NavLink to='/admin/news' exact>
						NEWS
					</NavLink>
				</li>
			)}
			<li>
				<NavLink to='/' exact>
					HOME
				</NavLink>
			</li>
			<li>
				<button onClick={logoutStuff} style={{ border: 'none' }}>
					LOGOUT
				</button>
			</li>
			<li></li>
		</ul>
	)
}

export default NavLinks
