//This is the Admin Registration List of Names Page.  It is used by admins to
//see who is registerd for a particular league/class/session/pickup/event, whatever.
import React, { useEffect, useState } from 'react'
import './AdminRegistration.css'
import { NavLink, Link, useParams } from 'react-router-dom'
import AdminButton from '../../shared/components/UIElements/AdminButton'
import emailjs from 'emailjs-com'
import Modal from '../../shared/components/UIElements/Modal'
import ModalEmail from '../../shared/components/UIElements/ModalEmail'
import AdminNavigation from './AdminNavigation'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import { useHttpClient } from '../../shared/hooks/http-hook'

const AdminRegistrationListOfNames = () => {
	console.log('inside list of names')
	const { sendRequest, clearError } = useHttpClient()
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [registrantId, setRegistrantId] = useState()
	const [showConfirmModal, setShowConfirmModal] = useState(false)
	const [loadedRegistrationLeagues, setLoadedRegistrationLeagues] = useState()
	const [showLeagueModal, setShowLeagueModal] = useState(false)
	const [emailModal, setEmailModal] = useState()
	const [loadedRegisteredPlayers, setLoadedRegisteredPlayers] = useState()
	const [registrantDeleted, setRegistrantDeleted] = useState()

	const modalFor = useParams().leagueNameAndDesc

	//First, lets tap into the following route on the backend to get all
	//the different leagues that are available for registration
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			setRegistrantDeleted(false)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL + `/registration/${modalFor}`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setLoadedRegisteredPlayers(responseData.allRegisteredPlayersInLeague)
				//setRegistrantDeleted(false)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setIsLoading(false)
		}
		sendRequest()
	}, [registrantDeleted])
	//
	//
	let emailAddressesArray
	emailAddressesArray = []
	loadedRegisteredPlayers &&
		loadedRegisteredPlayers.forEach((player) => {
			emailAddressesArray.push(player.email)
			emailAddressesArray.push(player.email2)
		})
	console.log('emailAddressesArray: ' + emailAddressesArray)
	//
	//
	const errorHandler = () => {
		setError(null)
	}
	//
	//
	const showEmailModal = (e) => {
		const value = e.target.id
		console.log('Lets send an email...')
		setEmailModal(true)
	}
	//
	//
	//
	//
	const cancelEmailHandler = () => {
		setEmailModal(false)
	}
	//
	//
	//
	function sendEmail(e) {
		e.preventDefault()

		emailjs
			.sendForm(
				'service_99rn8qe',
				'template_ntbamq8',
				document.getElementById('emailForm'),
				'fdVDMvL-S-25PCE_-'
			)
			.then((res) => {
				console.log('res: ' + JSON.stringify(res))
				//showEmailSentModal()
				setEmailModal(false)
				alert('Your email is on its way!')
			})
			.catch((err) => console.log(err))
	}
	//
	//
	const showDeleteWarningHandler = (e) => {
		console.log('e: ' + e.target.id)
		const value = e.target.id
		console.log(
			'Registered player you wish to delete: ' +
				JSON.stringify(loadedRegisteredPlayers[value])
		)
		setRegistrantId(loadedRegisteredPlayers[value]._id)
		setShowConfirmModal(true)
	}
	//
	//
	//
	//
	const cancelDeleteHandler = () => {
		setShowConfirmModal(false)
	}
	//
	//
	//
	//
	const confirmDeleteHandler = async () => {
		setShowConfirmModal(false)
		console.log('registrantId: ' + registrantId)
		try {
			await sendRequest(
				`${process.env.REACT_APP_BACKEND_URL}/admin/${registrantId}/removeRegistrant`,
				'DELETE'
			)
		} catch (err) {
			console.log(err)
		}
		setRegistrantDeleted(true)
		console.log('DELETING...')
	}
	//
	//
	//
	return (
		<React.Fragment>
			<ErrorModal error={error} onClear={errorHandler} />
			{isLoading && (
				<div className='center'>
					<LoadingSpinner />
				</div>
			)}
			<ModalEmail
				show={emailModal}
				onCancel={cancelEmailHandler}
				header='Send an Email'
				footerClass='place-item__modal-actions'
				footer={
					<React.Fragment>
						<div
							className='container'
							style={{ marginTop: '0.5rem', width: '80%' }}
						>
							<form
								method='post'
								enctype='text/plain'
								id='emailForm'
								style={{ height: '80%' }}
							>
								{/* <label>name</label>
								<input type='text' name='name' className='form-control' /> */}
								<label>Subject: </label>
								<input
									type='text'
									name='subject'
									className='form-control'
									defaultValue={'New Message from Anthony Higareda'}
									required
								></input>

								<br></br>
								<br></br>

								<label>From: </label>
								<input
									type='text'
									name='from'
									className='form-control'
									defaultValue='Anthony Higareda'
								></input>

								<br></br>
								<br></br>

								<label>To: </label>
								<input
									type='text'
									name='to'
									className='form-control'
									defaultValue={emailAddressesArray && emailAddressesArray}
								></input>

								<br></br>
								<br></br>

								<label>Message</label>
								<textarea name='message' rows='6' className='form-control' />
								<button class='buttonButton2' type='submit' onClick={sendEmail}>
									Send
								</button>
								<button
									className='buttonButton2'
									style={{ marginLeft: '2rem' }}
									onClick={cancelEmailHandler}
								>
									CANCEL
								</button>
							</form>
						</div>
						<br></br>
					</React.Fragment>
				}
			></ModalEmail>

			<Modal
				show={showConfirmModal}
				onCancel={cancelDeleteHandler}
				header='Are you sure?'
				footerClass='place-item__modal-actions'
				footer={
					<React.Fragment>
						<p className='modalText'>
							Are you sure you wish to delete this registered player?
						</p>
						<br></br>
						<button
							className='cancelDeleteButtons buttonButton'
							inverse
							style={{ float: 'right' }}
							onClick={cancelDeleteHandler}
						>
							CANCEL
						</button>
						<button
							className='cancelDeleteButtons buttonButton'
							style={{ color: 'red' }}
							danger
							onClick={confirmDeleteHandler}
						>
							DELETE REGISTERED PLAYER
						</button>
					</React.Fragment>
				}
			></Modal>
			{!isLoading && loadedRegisteredPlayers && (
				<div className='RegPlayers'>
					<AdminNavigation />
					<h1>Total registrations for {modalFor}</h1>
					<p className='paragraph'>
						Admins: Use this page to set up the type of league, event, or
						activity that users will register for. Whatever is added to this
						page will show up as an option on the 'Register' page.
					</p>
					<table id='regPlayers'>
						<thead>
							<tr>
								<th>Name</th>
								<th>Phone</th>
								<th className='address'>Address</th>
								<th>Email</th>
								<th>Email 2</th>
								{/* <th>Gender</th> */}
								<th>DOB</th>
								{/* <th>Size</th> */}
								<th>Parent</th>
								<th></th>
								<th></th>
								{/* <th>Goalie</th>
								<th>Number</th>
								<th>Position</th> */}
							</tr>
						</thead>
						<tbody>
							{loadedRegisteredPlayers.map((player, key) => {
								return (
									<tr key={key}>
										<td className='fuckingName'>
											{player.firstName} {player.middleInitial}{' '}
											{player.lastName}
										</td>
										<td>{player.phoneNumber}</td>
										<td className='address'>
											{player.address}, {player.city}
										</td>
										<td>{player.email}</td>
										<td>{player.email2}</td>
										<td>{player.dateOfBirth}</td>
										<td>{player.parentName}</td>
										<td>
											<NavLink
												className='navlinks'
												to={`/admin/updateRegistrant/${modalFor}/${player._id}`}
												exact
											>
												Edit
											</NavLink>
										</td>
										<td>
											<button
												className='buttonButton'
												style={{ color: 'red' }}
												id={key}
												onClick={(e) => showDeleteWarningHandler(e)}
											>
												DELETE
											</button>
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
					<div style={{ marginTop: '1rem' }}>
						<button
							className='okButton buttonButton2'
							// inverse
							onClick={(e) => showEmailModal(e)}
						>
							SEND AN EMAIL
						</button>
					</div>
				</div>
			)}
		</React.Fragment>
	)
}

export default AdminRegistrationListOfNames
