//This is the Admin Registration Setup Page.  It is used by admins to
//set up the type of league, event, or activity that users will register for.
//Whatever is added to this page will show up as an option for the users
//on the 'Register' page.
import React, { useEffect, useState } from 'react'
import Modal from '../../shared/components/UIElements/Modal'
import './AdminRegistration.css'
import { NavLink, Link } from 'react-router-dom'
import AdminButton from '../../shared/components/UIElements/AdminButton'
import AdminNavigation from './AdminNavigation'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import { useHttpClient } from '../../shared/hooks/http-hook'

const AdminRegistration = () => {
	const { sendRequest, clearError } = useHttpClient()
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [loadedRegistrationLeagues, setLoadedRegistrationLeagues] = useState()
	const [showConfirmModal, setShowConfirmModal] = useState(false)
	const [deletedRegistrationLeagueId, setDeletedRegistrationLeagueId] =
		useState('')
	const [registrationLeagueDeleted, setRegistrationLeagueDeleted] = useState()

	//First, lets tap into the following route on the backend to get all
	//the different leagues that are available for registration
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			setRegistrationLeagueDeleted(false)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL +
						`/registration/registrationLeagues`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setLoadedRegistrationLeagues(responseData.allRegistrationLeagues)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setIsLoading(false)
		}
		sendRequest()
	}, [registrationLeagueDeleted])

	const errorHandler = () => {
		setError(null)
	}

	//******************************************************** */
	//
	//     Delete warning
	//
	//******************************************************** */
	const showDeleteWarningHandler = (e) => {
		const value = e.target.id
		setDeletedRegistrationLeagueId(loadedRegistrationLeagues[value]._id)
		setShowConfirmModal(true)
	}

	const cancelDeleteHandler = () => {
		setShowConfirmModal(false)
	}

	const confirmDeleteHandler = async () => {
		setShowConfirmModal(false)
		try {
			await sendRequest(
				`${process.env.REACT_APP_BACKEND_URL}/admin/${deletedRegistrationLeagueId}/removeRegistrationLeague`,
				'DELETE'
			)
		} catch (err) {}
		setRegistrationLeagueDeleted(true)
	}

	return (
		<React.Fragment>
			<ErrorModal error={error} onClear={errorHandler} />
			{isLoading && (
				<div className='center'>
					<LoadingSpinner />
				</div>
			)}
			<Modal
				show={showConfirmModal}
				onCancel={cancelDeleteHandler}
				header='Are you sure?'
				footerClass='place-item__modal-actions'
				footer={
					<React.Fragment>
						<p className='modalText'>
							Are you sure you wish to delete this Registration?
						</p>
						<br></br>
						<button
							className='cancelDeleteButtons buttonButton'
							inverse
							style={{ float: 'right' }}
							onClick={cancelDeleteHandler}
						>
							CANCEL
						</button>
						<button
							className='cancelDeleteButtons buttonButton'
							style={{ color: 'red' }}
							danger
							onClick={confirmDeleteHandler}
						>
							DELETE REGISTRATION
						</button>
					</React.Fragment>
				}
			></Modal>
			{!isLoading && loadedRegistrationLeagues && (
				<div className='RegLeagues'>
					<AdminNavigation />
					<h1>Admin Registration Setup Page</h1>
					<p className='paragraph'>
						Admins: Use this page to set up the type of league, event, or
						activity that users will register for. Whatever is added to this
						page will show up as an option on the 'Register' page.
					</p>
					<table id='regLeagues'>
						<thead>
							<tr>
								<th>Type</th>
								<th>League Name/Details</th>
								<th>Registration Ends Date</th>
								<th>Price</th>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{loadedRegistrationLeagues.map((regLeague, key) => {
								return (
									<tr key={key}>
										<td>{regLeague.type}</td>
										<td>
											<Link
												style={{
													textDecoration: 'none',
													fontWeight: 600,
													color: 'black',
												}}
												to={`/admin/${regLeague.leagueNameAndDesc}`}
											>
												{regLeague.leagueNameAndDesc}
											</Link>
										</td>
										<td>{regLeague.leagueRegistrationCloseDate}</td>
										<td>${regLeague.leaguePrice}</td>
										<td>
											<NavLink
												className='navlinks'
												to={`/registration/updateRegistrationLeague/${regLeague._id}`}
												exact
											>
												Edit
											</NavLink>
										</td>
										<td>
											<button
												className='buttonButton'
												style={{ color: 'red' }}
												id={key}
												onClick={(e) => showDeleteWarningHandler(e)}
											>
												DELETE
											</button>
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>

					<AdminButton
						type='button'
						to={`/register/registrationLeague/new`}
						exact
						style={{
							color: 'white',
							textDecoration: 'none',
							backgroundColor: '#e0e0e0',
						}}
					>
						Add New Type of Registration
					</AdminButton>
				</div>
			)}
		</React.Fragment>
	)
}

export default AdminRegistration
