import React, { useContext } from 'react'
import './HomeNavLinks.css'
import { NavLink, Link } from 'react-router-dom'
import { AuthContext } from '../../context/auth-context'

const HomeNavLinks = (props) => {
	const auth = useContext(AuthContext)
	const propsString = props.leagueId

	let page

	if (auth.isLoggedIn) {
		//console.log('User is logged in!')
		page = '/admin/team/current'
	} else {
		//console.log('User is NOT logged in!')
		page = '/admin/login'
	}

	return (
		<ul className='home-nav-links'>
			{props.leagueId && (
				<li>
					<NavLink to={`/league/schedule/${propsString}`}>SCHEDULE</NavLink>
				</li>
			)}
			{!props.leagueId && (
				<li>
					<NavLink to={'/schedule'}>SCHEDULE</NavLink>
				</li>
			)}

			{props.leagueId && (
				<li>
					<NavLink to={`/league/${propsString}`}>STATS & STANDINGS</NavLink>
				</li>
			)}
			{!props.leagueId && (
				<li>
					<NavLink to={'/statsandstandings'}>PLAYER STATS</NavLink>
				</li>
			)}
			<li>
				<NavLink to={'/history'}>HISTORY</NavLink>
			</li>
			<li>
				<NavLink to={'/register'}>REGISTER</NavLink>
			</li>
			<li>
				<a href='/'>HOME</a>
			</li>
			<li>
				<NavLink to={page}>ADMIN</NavLink>
			</li>
		</ul>
	)
}

export default HomeNavLinks
