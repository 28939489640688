import React from 'react'
import SchedulesHomeNavigation from './SchedulesHomeNavigation'
//import SearchByLeagueOrVenue from '../UIElements/SearchByLeagueOrVenue'
//import FullSchedule from '../../schedule/FullSchedule'
import TeamSchedule from '../../schedule/TeamSchedule'

const SchedulesHome = () => {
	return (
		<div>
			<SchedulesHomeNavigation />
			{/* <SearchByLeagueOrVenue /> */}
			{/* <FullSchedule /> */}
			<TeamSchedule />
		</div>
	)
}

export default SchedulesHome
