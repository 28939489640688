import React from 'react'
import SchedulesHomeNavigation from './SchedulesHomeNavigation'
import TeamSchedule from '../../schedule/TeamSchedule'

const TeamSchedulesHome = () => {
	return (
		<div>
			<SchedulesHomeNavigation />
			<TeamSchedule />
		</div>
	)
}

export default TeamSchedulesHome
