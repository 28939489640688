//I've decided to do this page NOT by venue, but just a long list
import React, { useState, useEffect } from 'react'
import './AdminVenueSchedule.css'
import { NavLink, useParams } from 'react-router-dom'
import AdminNavigation from './AdminNavigation'
import AdminButton from '../../shared/components/UIElements/AdminButton'
import AdminSearchByVenue from './AdminSearchByVenue'
//import AdminSearchByLeague from './AdminSearchByLeague'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import Modal from '../../shared/components/UIElements/Modal'
import { useHttpClient } from '../../shared/hooks/http-hook'
//import Footer from '../../shared/components/UIElements/Footer'
//import SchedulesHomeNavigation from '../../shared/components/Navigation/SchedulesHomeNavigation'

const AdminVenueSchedule = () => {
	const { sendRequest, clearError } = useHttpClient()
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [allVenueGamesAndEvents, setAllVenueGamesAndEvents] = useState([])
	const [showConfirmModal, setShowConfirmModal] = useState(false)
	const [showConflictModal, setShowConflictModal] = useState(false)
	const [cancelHasBeenClicked, setCancelHasBeenClicked] = useState()
	const [eventDeleted, setEventDeleted] = useState()
	//const [allGamesAndEvents, setAllGamesAndEvents] = useState([])
	const [itemId, setItemId] = useState('')

	const venueId = useParams().venueId

	//Get all games scheduled at this venue via getVenueSchedule in league-controller
	useEffect(() => {
		//
		//console.log('INSIDE USE EFFECT')
		const sendRequest = async () => {
			setIsLoading(true)
			setAllVenueGamesAndEvents([])
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_URL}/league/${venueId}/schedule`
				)

				const responseData = await response.json()

				//console.log('venue games: ' + JSON.stringify(responseData))

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setAllVenueGamesAndEvents(responseData.allVenueGamesAndEventsArray)

				setEventDeleted(false)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setIsLoading(false)
		}
		sendRequest()
	}, [venueId, eventDeleted])
	//
	//
	const cancelConflictHandler = () => {
		setShowConflictModal(false)
		setCancelHasBeenClicked(true)
	}
	//
	//
	//
	//Let's strip the list down to just date, times, and venues, then we'll check to see if we
	//have any duplicates.  We dont want duplicates - that would mean a scheduling conflict.
	let dateTimeVenueArray, dateTimeVenue
	dateTimeVenueArray = []
	if (allVenueGamesAndEvents.length !== 0) {
		for (let i = 0; i < allVenueGamesAndEvents.length; i++) {
			dateTimeVenue =
				allVenueGamesAndEvents[i].date +
				'   ' +
				allVenueGamesAndEvents[i].time +
				' at ' +
				allVenueGamesAndEvents[i].venueName
			dateTimeVenueArray.push(dateTimeVenue)
		}
	}
	//
	//
	const errorHandler = () => {
		setError(null)
	}
	//
	let duplicateElements
	let dupes
	dupes = []
	if (dateTimeVenueArray.length !== 0 && !duplicateElements) {
		const findDuplicates = (dateTimeVenueArray) =>
			dateTimeVenueArray.filter((item, index) => {
				if (dateTimeVenueArray.indexOf(item) !== index) {
					dupes.push(item)
				}
			})
		duplicateElements = findDuplicates(dateTimeVenueArray)
		//console.log('duplicateElements: ' + duplicateElements)
	}

	//If we do have dupes, lets turn it into a readable list so we can display it
	let dupesList
	dupesList = []
	if (dupes.length !== 0) {
		dupes.forEach((dupe) => {
			dupesList.push(<p>{dupe}</p>)
		})
	}
	//
	//
	let dateBlock
	dateBlock = []
	if (!isLoading && allVenueGamesAndEvents.length === 0) {
		return (
			<React.Fragment>
				<AdminNavigation />
				<AdminSearchByVenue />
				{/* <AdminSearchByLeague /> */}
				{/* <SchedulesHomeNavigation /> */}
				<div className='AdminLeagues' style={{ marginBottom: '2rem' }}>
					<h1>NOTHING SCHEDULED YET!</h1>
				</div>

				<div className='container-fluid'>
					<div className='row'>
						<div className='col-sm'></div>
						<div className='col-sm' align='center'>
							<tr style={{ backgroundColor: '#e0e0e0' }}>
								<td className='adminVenueTableData'>
									<AdminButton
										to={`/games/new`}
										exact
										style={{ color: 'white', textDecoration: 'none' }}
									>
										Add new Game
									</AdminButton>
								</td>
								<td className='adminVenueTableData'>
									<AdminButton
										to={`/admin/events/new`}
										exact
										style={{ color: 'white', textDecoration: 'none' }}
									>
										Add new Event
									</AdminButton>
								</td>
							</tr>
						</div>
						<div className='col-sm'></div>
					</div>
				</div>
			</React.Fragment>
		)
	}
	if (!isLoading && allVenueGamesAndEvents.length !== 0) {
		// distinctVenuesArray.forEach((venue) => {
		//allLeagueGames.forEach((game) => {
		dateBlock.push(
			<div className='AdminSchedule' style={{ marginTop: '4rem' }}>
				<AdminNavigation />
				<AdminSearchByVenue />
				{/* <AdminSearchByLeague /> */}
				{/* <h1>Schedule</h1> */}

				<h1>{allVenueGamesAndEvents[0].venueName}</h1>
				<div className='note' style={{ width: '70%', margin: 'auto' }}>
					<h5>
						<strong>NOTE:</strong> You cannot enter results for a game where
						either team is still 'TBD'. You cannot EDIT a game that has been
						completed. You can edit results (final score, goals, etc), but you
						cannot edit the details (teams, league, time) of a finished game. If
						necessary, delete the game and re-do it.
					</h5>
				</div>
				<table id='adminVenueScheduleDisplay'>
					<thead>
						<tr>
							<th>Date</th>
							<th>Time</th>
							{/* <th>Game or Event</th> */}
							<th>Status</th>
							<th>Score</th>
							{/* <th></th> */}
							<th>Opponent</th>
							<th>Playoff/Championship?</th>
							{/* <th>Venue</th> */}
							<th></th>
							<th></th>
							<th></th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{allVenueGamesAndEvents.map((game, key) => {
							// if (game.venueName === venue) {
							return (
								<tr key={key}>
									<td>
										{game.dayOfWeek}
										{', '}
										{game.date.substr(0, 2) === '01' &&
											'Jan. ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '02' &&
											'Feb. ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '03' &&
											'Mar. ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '04' &&
											'Apr. ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '05' &&
											'May ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '06' &&
											'June ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '07' &&
											'July ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '08' &&
											'Aug. ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '09' &&
											'Sept. ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '10' &&
											'Oct. ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '11' &&
											'Nov. ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '12' &&
											'Dec. ' + game.date.substr(3, 2)}
									</td>
									<td>
										{game.time.substr(0, 2) === '00' &&
											'12:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '01' &&
											'1:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '02' &&
											'2:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '03' &&
											'3:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '04' &&
											'4:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '05' &&
											'5:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '06' &&
											'6:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '07' &&
											'7:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '08' &&
											'8:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '09' &&
											'9:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '10' &&
											'10:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '11' &&
											'11:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '12' &&
											'12:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '13' &&
											'1:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '14' &&
											'2:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '15' &&
											'3:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '16' &&
											'4:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '17' &&
											'5:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '18' &&
											'6:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '19' &&
											'7:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '20' &&
											'8:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '21' &&
											'9:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '22' &&
											'10:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '23' &&
											'11:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '24' &&
											'12:' + game.time.substr(3, 2) + ' am'}
									</td>
									<td>{game.status || 'TBP'}</td>
									<td>{game.score}</td>
									{game.opponent && <td>{game.opponent}</td>}
									{game.eventName && (
										<td
											style={{
												fontWeight: 'bold',
												backgroundColor: 'pink',
											}}
										>
											{game.eventName}
										</td>
									)}
									<td style={{ color: 'red', fontWeight: 'bold' }}>
										{game.playoff && 'PLAYOFF GAME'}
										{game.championship && 'CHAMPIONSHIP'}
									</td>
									<td>
										{game.opponent &&
											game.status !== 'FINAL' &&
											game.status !== 'Shootout' &&
											game.status !== 'Overtime' &&
											((game.opponent && (
												<NavLink
													className='navlinks'
													to={`/admin/updateGame/${game._id}`}
													exact
												>
													Edit
												</NavLink>
											)) || (
												<NavLink
													className='navlinks'
													to={`/admin/updateEvent/${game._id}`}
													exact
												>
													Edit
												</NavLink>
											))}
										{!game.opponent && (
											<NavLink
												className='navlinks'
												to={`/admin/updateEvent/${game._id}`}
												exact
											>
												Edit
											</NavLink>
										)}
									</td>
									<td>
										{game.playoff &&
											game.opponent &&
											game.opponent !== 'TBD' && (
												<NavLink
													className='navlinks'
													to={`/admin/playoffGameResults/${game._id}`}
													exact
												>
													Results
												</NavLink>
											)}
										{game.championship &&
											game.opponent &&
											game.opponent !== 'TBD' && (
												<NavLink
													className='navlinks'
													to={`/admin/championshipGameResults/${game._id}`}
													exact
												>
													Results
												</NavLink>
											)}
										{!game.playoff &&
											!game.championship &&
											game.opponent &&
											game.opponent !== 'TBD' && (
												<NavLink
													className='navlinks'
													to={`/admin/gameResults/${game._id}`}
													exact
												>
													Results
												</NavLink>
											)}
									</td>
									<td>
										<button
											className='buttonButton'
											style={{ color: 'red' }}
											id={key}
											onClick={(e) => showDeleteWarningHandler(e)}
										>
											DELETE
										</button>
									</td>
									<td></td>
								</tr>
							)
							//}
						})}
					</tbody>
				</table>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-sm'></div>
						<div className='col-sm' align='center'>
							<tr style={{ backgroundColor: '#e0e0e0' }}>
								<td className='adminVenueTableData'>
									<AdminButton
										to={`/games/new`}
										exact
										style={{ color: 'white', textDecoration: 'none' }}
									>
										Add new Game
									</AdminButton>
								</td>
								<td className='adminVenueTableData'>
									<AdminButton
										to={`/admin/events/new`}
										exact
										style={{ color: 'white', textDecoration: 'none' }}
									>
										Add new Event
									</AdminButton>
								</td>
							</tr>
						</div>
						<div className='col-sm'></div>
					</div>
				</div>
			</div>
		)
		// }
		//)
	}

	const showDeleteWarningHandler = (e) => {
		console.log('e: ' + e.target.id)
		const value = e.target.id
		console.log(
			'Game you wish to delete: ' +
				JSON.stringify(allVenueGamesAndEvents[value])
		)
		setItemId(allVenueGamesAndEvents[value]._id)
		setShowConfirmModal(true)
	}
	//
	//
	//
	//
	const cancelDeleteHandler = () => {
		setShowConfirmModal(false)
	}
	//
	//
	//
	//
	const confirmDeleteHandler = async () => {
		setShowConfirmModal(false)
		console.log('itemId: ' + itemId)
		try {
			await sendRequest(
				`${process.env.REACT_APP_BACKEND_URL}/admin/${itemId}/removeEvent`, //can be event OR game
				'DELETE'
			)
		} catch (err) {
			console.log(err)
		}
		setEventDeleted(true)
		console.log('DELETING...')
	}

	return (
		<React.Fragment>
			<ErrorModal error={error} onClear={errorHandler} />
			{isLoading && (
				<div className='center'>
					<LoadingSpinner />
				</div>
			)}
			<Modal
				show={showConfirmModal}
				onCancel={cancelDeleteHandler}
				header='Are you sure?'
				footerClass='place-item__modal-actions'
				footer={
					<React.Fragment>
						<p className='modalText'>
							Are you sure you wish to delete this item?
						</p>
						<br></br>
						<button
							className='cancelDeleteButtons buttonButton'
							inverse
							style={{ float: 'right' }}
							onClick={cancelDeleteHandler}
						>
							CANCEL
						</button>
						<button
							className='cancelDeleteButtons buttonButton'
							style={{ color: 'red' }}
							danger
							onClick={confirmDeleteHandler}
						>
							DELETE GAME OR EVENT
						</button>
					</React.Fragment>
				}
			></Modal>
			<Modal
				//show={showConflictModal}
				show={dupes.length > 0 && !cancelHasBeenClicked}
				onCancel={cancelConflictHandler}
				header='SCHEDULING CONFLICT!!'
				footerClass='place-item__modal-actions'
				footer={
					<React.Fragment>
						<h3>
							We have multiple items scheduled for the same date, time, and
							place!
						</h3>
						<p>{dupesList}</p>
						<br></br>
						<button
							className='cancelDeleteButtons'
							inverse
							style={{ float: 'right' }}
							onClick={cancelConflictHandler}
						>
							OK
						</button>
					</React.Fragment>
				}
			></Modal>
			{/* <SchedulesHomeNavigation /> */}
			{dateBlock && dateBlock}
			{/* <Footer /> */}
		</React.Fragment>
	)
}

export default AdminVenueSchedule
