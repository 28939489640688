import React, { useContext, useState } from 'react'
import '../components/AdminHeader.css'
import './AdminPage.css'
import Card from '../../shared/components/UIElements/Card'
import Input from '../../shared/components/FormElements/Input'
import Button from '../../shared/components/UIElements/Button'
import Footer from '../../shared/components/UIElements/Footer'
import {
	VALIDATOR_EMAIL,
	VALIDATOR_MINLENGTH,
} from '../../shared/util/validators'
import { useForm } from '../../shared/hooks/form-hook'
import { AuthContext } from '../../shared/context/auth-context'
import { useNavigate } from 'react-router-dom'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import StatsAndStandingsHomeNavigation from '../../shared/components/Navigation/StatsAndStandingsHomeNavigation'

const LoginPage = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const auth = useContext(AuthContext)

	const [formState, inputHandler] = useForm(
		{
			email: {
				value: '',
				isValid: false,
			},
			password: {
				value: '',
				isValid: false,
			},
		},
		false
	)

	let navigate = useNavigate()

	const authSubmitHandler = async (event) => {
		event.preventDefault()
		//console.log(formState.inputs)

		try {
			setIsLoading(true)
			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL + '/admin/login',
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						email: formState.inputs.email.value,
						password: formState.inputs.password.value,
					}),
				}
			)
			const responseData = await response.json()
			if (!response.ok) {
				throw new Error(responseData.message)
			}
			console.log(responseData)
			setIsLoading(false)
			//auth.login()
			auth.login(responseData.token)
			navigate(`/admin/team/current`)
		} catch (err) {
			console.log(err)
			setIsLoading(false)
			setError(err.message || 'Something went wrong with logging in')
		}
	}

	const errorHandler = () => {
		setError(null)
	}

	return (
		<React.Fragment>
			<StatsAndStandingsHomeNavigation />
			<ErrorModal error={error} onClear={errorHandler} />
			{isLoading && <LoadingSpinner asOverlay />}
			<Card className='authentication' style={{ marginBottom: '12rem' }}>
				<h2 style={{ marginTop: '1rem' }}>Login Required</h2>
				<hr />
				<form onSubmit={authSubmitHandler}>
					<Input
						element='input'
						id='email'
						type='email'
						label='E-mail'
						validators={[VALIDATOR_EMAIL()]}
						errorText='Please enter a valid email address.'
						onInput={inputHandler}
					/>
					<Input
						element='input'
						id='password'
						type='password'
						label='Password'
						validators={[VALIDATOR_MINLENGTH(8)]}
						errorText='Please enter a valid password.'
						onInput={inputHandler}
					/>
					<Button type='submit' disabled={!formState.isValid}>
						Admin Login
					</Button>
				</form>
			</Card>
			<Footer />
		</React.Fragment>
	)
}

export default LoginPage
