import React from 'react'
import './Footer.css'

const Footer = () => {
	return (
		<div className='section footer-background px-5'>
			<div className=''>
				<div className='row text-light py-4 px-5'>
					<div className='col-lg-3 col-sm-6'>
						<h3 className='pb-3 footer-h5'>ADDRESS</h3>
						<p className='medium footer-p text-sh-red'>
							402 W Washington Ave <br></br> Magnolia, NJ
						</p>
					</div>
					<div className='col-lg-3 col-sm-6'>
						<h3 className='pb-3 footer-h5'>PHONE</h3>
						<p className='medium footer-p text-sh-red'>856.701.2390</p>
					</div>
					<div className='col-lg-3 col-sm-6'>
						<h3 className='pb-3 footer-h5'>EMAIL</h3>
						<p className='medium footer-p text-sh-red'>
							<a href='mailto:ahigareda@onmail.com'>ahigareda@onmail.com</a>
						</p>
					</div>
					<div className='col-lg-3 col-sm-6'>
						<h3 className='pb-3 footer-h5'>INSTAGRAM</h3>
						<p className='medium footer-p text-sh-red'>
							<a
								href='https://www.instagram.com/sjsloths_bhc/'
								target='_blank'
								style={{ color: 'white' }}
							>
								<i className='fab fa-instagram fa-2x'></i>
							</a>
						</p>
					</div>
					{/* <div className='col-lg-3 col-sm-6'>
						<ul className='list-inline'>
							<h3 className='pb-3 footer-h5'>INSTA</h3>
							<li className='list-inline-item p-4'>
								<a
									href='https://www.instagram.com/sjsloths_bhc/'
									target='_blank'
									style={{ color: 'white' }}
								>
									<i className='fab fa-instagram fa-2x'></i>
								</a>
							</li>
						</ul>
					</div> */}
				</div>
				<div className='row'>
					<p
						className='py-4 m-0 copyright text-center'
						style={{ color: 'white' }}
					>
						A Web Application by{' '}
						<a
							href='https://www.shireystatsandstandings.com/#'
							target='_blank'
							style={{ color: 'white' }}
						>
							Shirey Stats and Standings, LLC
						</a>
					</p>
				</div>
			</div>
		</div>
	)
}

export default Footer
