import React from 'react'

import Modal from './Modal'
import Button from './Button'

const ErrorModal = (props) => {
	//console.log('inside ErrorModal.js ' + props.error + ' ' + props.onClear)
	return (
		<Modal
			onCancel={props.onClear}
			header='An Error Occurred!'
			show={!!props.error}
			footer={<Button onClick={props.onClear}>Okay</Button>}
		>
			<p>{props.error}</p>
		</Modal>
	)
}

export default ErrorModal
