import React, { useEffect, useState } from 'react'
import './AdminGameResults.css'
import AdminNavigation from './AdminNavigation'
import { useNavigate, useParams } from 'react-router-dom'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
//import Button from '../../shared/components/UIElements/Button'
import AdminButton from '../../shared/components/UIElements/AdminButton'

const AdminGameResultsChampionship = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [homeTeamName, setHomeTeamName] = useState()
	const [homeRoster, setHomeRoster] = useState()
	const [opponent, setOpponent] = useState()
	const [championshipGameStats, setChampionshipGameStats] = useState([])
	const [rosterPlayerGameStats, setRosterPlayerGameStats] = useState()
	const [dayOfWeek, setDayOfWeek] = useState()
	const [date, setDate] = useState()
	const [time, setTime] = useState()
	const [venue, setVenue] = useState()
	const gameId = useParams().gameId

	//Here, I want to go to the backend, using the playoffGameId, to get the
	//game date, time, venue, home team, and visitor team.
	//If stats already exist for this playoff game (like, we needed to edit the score),
	//we'll get that information as well.
	//getGameRostersAndPointsPerPeriod in admin-controller.js
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_URL}/admin/${gameId}/rostersAndPoints`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}
				setHomeRoster(responseData.homeRoster)
				setHomeTeamName(responseData.teamName)
				setOpponent(responseData.opponent)
				setRosterPlayerGameStats(responseData.rosterPlayerGameStats)
				setChampionshipGameStats(responseData.gameStats)
				setDayOfWeek(responseData.dayOfWeek)
				setDate(responseData.date)
				setTime(responseData.time)
				setVenue(responseData.venue)
			} catch (err) {
				setError(err)
			}
			setIsLoading(false)
		}
		sendRequest()
	}, [gameId])

	console.log('championshipGameStats: ' + championshipGameStats)

	let homePointsTotal, visitorPointsTotal, status, summary, score
	if (championshipGameStats) {
		if (championshipGameStats.length > 0) {
			console.log('WE HAVE GAME STATS from this CHAMPIONSHIP game')
			console.log(
				'championshipGameStats: ' + JSON.stringify(championshipGameStats)
			)
			homePointsTotal = championshipGameStats[0].homeGoalsTotal
			visitorPointsTotal = championshipGameStats[0].visitorGoalsTotal
			status = championshipGameStats[0].status
			summary = championshipGameStats[0].summary
			console.log('summary here: ' + summary)
			console.log('homeGoalsTotal here: ' + homePointsTotal)
		}
	} else {
		console.log('NO GAME STATS YET!!!')
	}
	//
	const errorHandler = () => {
		setError(null)
	}
	//
	//
	//
	//
	const returnGoals = (playerId) => {
		let gameGoals
		rosterPlayerGameStats.forEach((rosterPlayer) => {
			if (rosterPlayer.rosterPlayerId === playerId) {
				gameGoals = rosterPlayer.goals
			}
		})
		return gameGoals
	}
	//
	//
	//
	const returnAssists = (playerId) => {
		let gameAssists
		rosterPlayerGameStats.forEach((rosterPlayer) => {
			if (rosterPlayer.rosterPlayerId === playerId) {
				gameAssists = rosterPlayer.assists
			}
		})
		return gameAssists
	}
	//
	//
	//
	let navigate = useNavigate()
	//Here, I want to tap into the backend and update stats for the game and rosterPlayers
	//Maybe...or maybe I want to do it another way...
	const logChampionshipGameStats = async () => {
		const gameSummary = document.getElementById('gameSummary').value
		const homePointsTotal = document.getElementById('homeGoalsTotal').value
		const visitorPointsTotal =
			document.getElementById('visitorGoalsTotal').value
		//**************************************************** */
		//
		//  Individual Player stats:
		//
		//***************************************************** */
		//
		if (homePointsTotal == visitorPointsTotal) {
			alert('You cant have a TIE in a Championship game!')
		} else {
			let homeStats
			homeStats = []
			//
			homeRoster.forEach((rosterPlayer) => {
				homeStats.push(
					rosterPlayer._id +
						'|' +
						document.getElementById('goals' + rosterPlayer._id).value +
						'|' +
						document.getElementById('assists' + rosterPlayer._id).value
				)
			})

			try {
				//tapping into backend to add championship game stats...
				// admin-controller    createChampionshipGameStats
				setIsLoading(true)
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_URL}/admin/championshipGameStats/${gameId}`,
					{
						method: 'PATCH',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							homeStats,
							homePointsTotal,
							visitorPointsTotal,
							gameSummary,
							gameId,
						}),
					}
				)
				const responseData = await response.json()
				if (!response.ok) {
					throw new Error(responseData.message)
				}
				setIsLoading(false)
				navigate('/admin/masterSchedule')
			} catch (err) {
				console.log('error: ' + err)
				setIsLoading(false)
				setError(
					err.message ||
						'Something went wrong with adding championship game stats'
				)
			}
		}
	}
	//
	//
	//
	//
	return (
		<React.Fragment>
			<AdminNavigation />
			<ErrorModal error={error} onClear={errorHandler} />
			{isLoading && (
				<div className='center'>
					<LoadingSpinner />
				</div>
			)}
			<span>
				<h1 style={{ marginTop: '5rem' }}>
					<h1 style={{ color: 'red', fontWeight: 'bold' }}>
						CHAMPIONSHIP GAME!
					</h1>{' '}
					{homeTeamName} vs. {opponent}
				</h1>
				<p style={{ textAlign: 'center' }}>
					{dayOfWeek},{' '}
					{date && date.substr(0, 2) === '01' && 'Jan. ' + date.substr(3, 2)}
					{date && date.substr(0, 2) === '02' && 'Feb. ' + date.substr(3, 2)}
					{date && date.substr(0, 2) === '03' && 'Mar. ' + date.substr(3, 2)}
					{date && date.substr(0, 2) === '04' && 'Apr. ' + date.substr(3, 2)}
					{date && date.substr(0, 2) === '05' && 'May ' + date.substr(3, 2)}
					{date && date.substr(0, 2) === '06' && 'June ' + date.substr(3, 2)}
					{date && date.substr(0, 2) === '07' && 'July ' + date.substr(3, 2)}
					{date && date.substr(0, 2) === '08' && 'Aug. ' + date.substr(3, 2)}
					{date && date.substr(0, 2) === '09' && 'Sept. ' + date.substr(3, 2)}
					{date && date.substr(0, 2) === '10' && 'Oct. ' + date.substr(3, 2)}
					{date && date.substr(0, 2) === '11' && 'Nov. ' + date.substr(3, 2)}
					{date &&
						date.substr(0, 2) === '12' &&
						'Dec. ' + date.substr(3, 2)},{' '}
					{time &&
						time.substr(0, 2) === '01' &&
						'1:' + time.substr(3, 2) + ' am'}
					{time &&
						time.substr(0, 2) === '02' &&
						'2:' + time.substr(3, 2) + ' am'}
					{time &&
						time.substr(0, 2) === '03' &&
						'3:' + time.substr(3, 2) + ' am'}
					{time &&
						time.substr(0, 2) === '04' &&
						'4:' + time.substr(3, 2) + ' am'}
					{time &&
						time.substr(0, 2) === '05' &&
						'5:' + time.substr(3, 2) + ' am'}
					{time &&
						time.substr(0, 2) === '06' &&
						'6:' + time.substr(3, 2) + ' am'}
					{time &&
						time.substr(0, 2) === '07' &&
						'7:' + time.substr(3, 2) + ' am'}
					{time &&
						time.substr(0, 2) === '08' &&
						'8:' + time.substr(3, 2) + ' am'}
					{time &&
						time.substr(0, 2) === '09' &&
						'9:' + time.substr(3, 2) + ' am'}
					{time &&
						time.substr(0, 2) === '10' &&
						'10:' + time.substr(3, 2) + ' am'}
					{time &&
						time.substr(0, 2) === '11' &&
						'11:' + time.substr(3, 2) + ' am'}
					{time &&
						time.substr(0, 2) === '12' &&
						'12:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '13' &&
						'1:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '14' &&
						'2:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '15' &&
						'3:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '16' &&
						'4:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '17' &&
						'5:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '18' &&
						'6:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '19' &&
						'7:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '20' &&
						'8:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '21' &&
						'9:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '22' &&
						'10:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '23' &&
						'11:' + time.substr(3, 2) + ' pm'}
					{time &&
						time.substr(0, 2) === '24' &&
						'12:' + time.substr(3, 2) + ' am'}
					, {venue}
				</p>
			</span>
			<div className='row'>
				<div className='col-sm'></div>
				<div className='col-sm'></div>
			</div>

			<div className='row' style={{ paddingBottom: '2rem' }}>
				<div className='col-sm'></div>
				<div className='col-sm gameSummary'>
					<label for='textarea'>GAME SUMMARY:</label>
					<textarea
						className='summaryTextArea'
						id='gameSummary'
						defaultValue={summary}
						placeholder='Good game?  Write something about it...'
						rows='2'
						cols='35'
					></textarea>
				</div>
				<div className='col-sm'></div>
			</div>

			<table className='AdminRoster' id='adminPlayoffRoster'>
				<thead>
					<tr>
						<th>Team</th>
						<th>Final</th>
					</tr>
				</thead>
				<tbody>
					<tr style={{ height: '5px' }}>
						<td style={{ fontWeight: 'bold', fontSize: 'xx-large' }}>
							{homeTeamName}
						</td>
						<td>
							<input
								id='homeGoalsTotal'
								type='number'
								min='0'
								max='99'
								defaultValue={homePointsTotal}
							/>
						</td>
					</tr>
					<tr>
						<td style={{ fontWeight: 'bold', fontSize: 'xx-large' }}>
							{opponent}
						</td>
						<td>
							<input
								id='visitorGoalsTotal'
								type='number'
								min='0'
								max='99'
								defaultValue={visitorPointsTotal}
							/>
						</td>
					</tr>
				</tbody>
			</table>
			<div className='row'>
				<div className='col' /* style={{ marginLeft: '5%' }} */>
					{!isLoading && homeRoster && (
						<div className='AdminRoster2'>
							<h1>{homeTeamName} - Player Stats</h1>
							<table id='adminRoster' style={{ width: '40%' }}>
								<thead>
									<tr>
										<th>#</th>
										<th>Player</th>
										<th>Goals</th>
										<th>Assists</th>
									</tr>
								</thead>
								<tbody>
									{homeRoster.map((player, key) => {
										return (
											<tr key={key}>
												{/* <td>{player.number}</td> */}
												<td>{player.number}</td>
												<td>
													{player.firstName}{' '}
													{player.middleInitial && player.middleInitial}{' '}
													{player.lastName}
												</td>
												<td>
													<input
														id={'goals' + player._id}
														type='number'
														defaultValue={returnGoals(player._id)}
														min='0'
														max='99'
													/>
												</td>
												<td>
													<input
														id={'assists' + player._id}
														type='number'
														defaultValue={returnAssists(player._id)}
														min='0'
														max='99'
													/>
												</td>
											</tr>
										)
									})}
								</tbody>
							</table>
						</div>
					)}
				</div>
			</div>
			<div
				style={{
					width: '7%',
					margin: 'auto',
					paddingTop: '3%',
					marginBottom: '3%',
				}}
			>
				<AdminButton onClick={logChampionshipGameStats}>SAVE</AdminButton>
			</div>
		</React.Fragment>
	)
}

export default AdminGameResultsChampionship
