//non admin
//I've decided to do this page NOT by venue, but just a long list
import React, { useState, useEffect } from 'react'
import './FullSchedule.css'
import { Link } from 'react-router-dom'
import Footer from '../components/UIElements/Footer'
import LoadingSpinner from '../components/UIElements/LoadingSpinner'
import SchedulesHomeNavigation from '../components/Navigation/SchedulesHomeNavigation'
import sloths from '../../shared/components/images/Sloths_Name.png'

const TeamSchedule = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [year, setYear] = useState()
	const [teamName, setTeamName] = useState()
	const [wins, setWins] = useState()
	const [losses, setLosses] = useState()
	const [overtimeLosses, setOvertimeLosses] = useState()
	const [shootoutLosses, setShootoutLosses] = useState()
	const [ties, setTies] = useState()
	const [allTeamGamesAndEvents, setAllTeamGamesAndEvents] = useState([])

	//Get all games for a team.  getTeamSchedule2.  Also returns teamName.
	useEffect(() => {
		//console.log('INSIDE USE EFFECT')
		const sendRequest = async () => {
			setIsLoading(true)
			setAllTeamGamesAndEvents([])
			try {
				const response = await fetch(
					// `${process.env.REACT_APP_BACKEND_URL}/league/teamSchedule/${leagueName}/${session}/${year}/${teamName}`
					`${process.env.REACT_APP_BACKEND_URL}/league/teamSchedule`
				)

				const responseData = await response.json()

				//console.log('responseData: ' + JSON.stringify(responseData))

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setAllTeamGamesAndEvents(responseData.allGamesAndEventsArray)
				setTeamName(responseData.teamName)
				setYear(responseData.year)
				setWins(responseData.wins)
				setLosses(responseData.losses)
				setOvertimeLosses(responseData.overtimeLosses)
				setShootoutLosses(responseData.shootoutLosses)
				setTies(responseData.ties)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setIsLoading(false)
		}
		sendRequest()
	}, [year, teamName])
	//
	//
	console.log('teamName: ' + teamName)
	console.log('overtimeLosses: ' + overtimeLosses)
	console.log('shootoutLosses: ' + shootoutLosses)

	//
	if (isLoading) {
		return (
			<div className='center' style={{ marginTop: '10rem' }}>
				<LoadingSpinner />
			</div>
		)
	}
	//
	//
	//
	//
	const errorHandler = () => {
		setError(null)
	}

	let dateBlock
	dateBlock = []
	if (!isLoading && allTeamGamesAndEvents.length === 0) {
		return (
			<React.Fragment>
				<SchedulesHomeNavigation />
				<div style={{ marginTop: '6rem' }} className='Leagues'>
					<div>
						<img src={sloths} className='sloths' />
					</div>{' '}
					<h1>NOTHING SCHEDULED YET!</h1>
				</div>
				<div style={{ marginTop: '25rem' }}>
					<Footer />
				</div>
			</React.Fragment>
		)
	}
	if (!isLoading && allTeamGamesAndEvents.length !== 0) {
		dateBlock.push(
			<div style={{ marginTop: '6rem' }} className='Schedule'>
				{/* <AdminNavigation /> */}
				<div>
					<img src={sloths} className='sloths' />
				</div>
				<h2>
					Record: {wins} - {losses} - {ties + shootoutLosses + overtimeLosses}
				</h2>
				<h3 className='h3Center'>{teamName + ' - ' + year}</h3>
				<p>(Click on the final score of a game to see a Game Summary)</p>
				<table id='teamScheduleDisplay'>
					<thead>
						<tr>
							<th>Date</th>
							<th>Time</th>
							<th>Status</th>
							<th>Score</th>
							<th>Opponent</th>
							<th></th>
							<th>Venue</th>
						</tr>
					</thead>
					<tbody>
						{allTeamGamesAndEvents.map((game, key) => {
							// if (game.venueName === venue) {
							return (
								<tr key={key}>
									<td>
										{game.dayOfWeek}
										{', '}
										{game.date.substr(0, 2) === '01' &&
											'Jan. ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '02' &&
											'Feb. ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '03' &&
											'Mar. ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '04' &&
											'Apr. ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '05' &&
											'May ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '06' &&
											'June ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '07' &&
											'July ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '08' &&
											'Aug. ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '09' &&
											'Sept. ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '10' &&
											'Oct. ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '11' &&
											'Nov. ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '12' &&
											'Dec. ' + game.date.substr(3, 2)}
									</td>
									<td>
										{game.time.substr(0, 2) === '00' &&
											'12:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '01' &&
											'1:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '02' &&
											'2:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '03' &&
											'3:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '04' &&
											'4:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '05' &&
											'5:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '06' &&
											'6:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '07' &&
											'7:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '08' &&
											'8:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '09' &&
											'9:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '10' &&
											'10:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '11' &&
											'11:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '12' &&
											'12:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '13' &&
											'1:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '14' &&
											'2:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '15' &&
											'3:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '16' &&
											'4:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '17' &&
											'5:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '18' &&
											'6:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '19' &&
											'7:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '20' &&
											'8:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '21' &&
											'9:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '22' &&
											'10:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '23' &&
											'11:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '24' &&
											'12:' + game.time.substr(3, 2) + ' am'}
									</td>
									<td>
										{(game.status && game.status !== 'TBP' && (
											<Link
												style={{
													textDecoration: 'none',
													fontWeight: 600,
													color: 'black',
												}}
												to={`/gameSummary/${game._id}`}
											>
												{game.status}
											</Link>
										)) ||
											'TBP'}
									</td>
									{!game.score && <td></td>}
									{game.score && !game.winner && !game.loser && (
										<td style={{ color: 'black', fontWeight: 'bold' }}>
											<Link
												style={{
													textDecoration: 'none',
													fontWeight: 600,
													color: 'black',
												}}
												to={`/gameSummary/${game._id}`}
											>
												TIED {game.score}
											</Link>
										</td>
									)}
									{game.winner === game.opponent && !game.eventName && (
										<td style={{ color: 'red', fontWeight: 'bold' }}>
											<Link
												style={{
													textDecoration: 'none',
													fontWeight: 600,
													color: 'red',
												}}
												to={`/gameSummary/${game._id}`}
											>
												LOST {game.score}
											</Link>
										</td>
									)}
									{game.winner === teamName && (
										<td style={{ color: 'green', fontWeight: 'bold' }}>
											<Link
												style={{
													textDecoration: 'none',
													fontWeight: 600,
													color: 'green',
												}}
												to={`/gameSummary/${game._id}`}
											>
												WON {game.score}
											</Link>
										</td>
									)}
									{game.opponent && <td>{game.opponent}</td>}
									{game.eventName && (
										<td
											style={{
												fontWeight: 'bold',
												backgroundColor: 'pink',
											}}
										>
											{game.eventName}
										</td>
									)}
									{!game.playoff && !game.championship && <td></td>}
									{game.playoff && (
										<td style={{ color: 'red', fontWeight: 'bold' }}>
											PLAYOFF GAME
										</td>
									)}

									{game.championship && (
										<td style={{ color: 'red', fontWeight: 'bold' }}>
											CHAMPIONSHIP GAME
										</td>
									)}
									<td>{game.venueName}</td>
								</tr>
							)
							//}
						})}
					</tbody>
				</table>
			</div>
		)
		// }
		//)
	}

	return (
		<React.Fragment>
			<SchedulesHomeNavigation />
			{dateBlock && dateBlock}
			<Footer />
		</React.Fragment>
	)
}

export default TeamSchedule
